<template>
  <div :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="this.show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="`Classificação de Negócios ${
        !this.selected || this.selected.length == 0
          ? ' | ' + this.businessName
          : ''
      }`"
      :modal="true"
      :closable="false"
    >
      <form
        name="classification-business-form"
        class="p-col-12"
        @submit.prevent="saveClassification"
        style="min-height: 40vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-field p-col-12 p-md-12 p-mt-2"
            v-if="can('setSellerEditBusinessClassification')"
          >
            <span class="p-float-label">
              <Dropdown
                name="status"
                v-model="classification.currentStatus"
                :options="statusList"
                placeholder="Selecione o Estado do negócio"
                :optionLabel="'label'"
                :optionValue="'id'"
                v-bind:class="['form-control']"
              >
              </Dropdown>
              <label for="status">Estado</label>
            </span>
          </div>
          <div
            class="p-field p-col-12 p-md-4 p-mt-3"
            v-if="classification.currentStatus == 2"
          >
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="caseStudy">Caso de Estudo</label>
            </span>
            <InputSwitch
              v-model="classification.case_study"
              id="caseStudy"
              inputId="caseStudy"
              :name="'caseStudy'"
              style="'top: 3px;"
              @change="caseStudyChange()"
            />
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <InputText
                v-model="classification.marketing_name"
                :autoResize="true"
                v-bind:class="['form-control']"
                :id="'marketingName'"
                :name="'marketingName'"
                :disabled="!can('editBusinessNameToClassification')"
              />
              <label for="marketingName">Nome para o Marketing</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                name="businessSeller"
                :options="sellerList"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'id'"
                v-model="current"
                v-bind:class="['form-control']"
              >
                <template #option="slotProps">
                  {{ slotProps.option.name }}
                </template>
              </Dropdown>
              <label for="businessSeller">Vendedor</label>
            </span>
          </div>

          <!-- <div class="p-field p-col-12 p-md-2 p-mt-5">
            <span
              class="p-float-label"
              style="top: -1.4rem; left: -0.5rem; font-size: 12px"
            >
              <label for="portfolio">Portfólio</label>
            </span>
            <InputSwitch
              v-model="classification.portfolio"
              id="portfolio"
              inputId="portfolio"
              :name="'portfolio'"
              :disabled="classification.case_study"
              style="'top: 3px;"
            />
          </div> -->
          <!-- <div class="p-field p-col-12 p-md-2 p-mt-5">
            <span
              class="p-float-label"
              :style="{ top: '-1.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="socialNetworks">Redes Sociais</label>
            </span>
            <InputSwitch
              v-model="classification.social_networks"
              id="socialNetworks"
              inputId="socialNetworks"
              :name="'socialNetworks'"
              style="'top: 3px;"
            />
          </div> -->
          <!-- <div class="p-field p-col-12 p-md-2 p-mt-5">
            <span
              class="p-float-label"
              style="top: -1.4rem; left: -0.5rem; font-size: 12px"
            >
              <label for="sellerEdit">Sites</label>
            </span>
            <InputSwitch
              v-model="classification.sites"
              id="sites"
              inputId="sites"
              :name="'sites'"
              style="'top: 3px;"
            />
          </div> -->
          <!-- <div
            class="p-field p-col-12 p-md-2 p-mt-5"
            v-if="can('setSellerEditBusinessClassification')"
          >
            <span
              class="p-float-label"
              :style="{ top: '-1.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="sellerEdit">Vendedor Edita</label>
            </span>
            <InputSwitch
              v-model="classification.seller_edit"
              id="sellerEdit"
              inputId="sellerEdit"
              :name="'sellerEdit'"
              style="'top: 3px;"
            />
          </div> -->

          <!-- <div
            class="p-field p-col-12 p-md-2 p-mt-5"
            v-if="can('hideBusinessClassification')"
          >
            <span
              class="p-float-label"
              style="top: -1.4rem; left: -0.5rem; font-size: 12px"
            >
              <label for="sellerEdit">Ocultar</label>
            </span>
            <InputSwitch
              v-model="classification.hide"
              id="hide"
              inputId="hide"
              :name="'hide'"
              style="'top: 3px;"
            />
          </div> -->
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="classification.description_project"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'descriptionProject'"
                :name="'descriptionProject'"
              />
              <label for="descriptionProject">Descrição do Projeto</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <MultiSelect
                name="solutions"
                id="solutions"
                inputId="solutions"
                :options="solutionsList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="classification.solutions"
                display="chip"
              >
              </MultiSelect>
              <label for="solutions">Soluções</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <MultiSelect
                name="businessArea"
                id="businessArea"
                inputId="businessArea"
                :options="businessAreasList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                v-model="classification.business_area"
                display="chip"
              >
              </MultiSelect>
              <label for="businessArea">Áreas de Negócio</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                v-model="classification.marketing_action"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'marketingAction'"
                :name="'marketingAction'"
              />
              <label for="marketingAction">Ações de Marketing</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="classification.actionsTaken"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'actionsTaken'"
                :name="'actionsTaken'"
              />
              <label for="actionsTaken">Ações de Efetuadas</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <FileUpload
              :ref="'filesInput'"
              :name="'files[]'"
              chooseLabel="Adicionar Ficheiros"
              :multiple="true"
              :showUploadButton="false"
              :showCancelButton="false"
              :maxFileSize="2000000000"
            />
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-for="file in filesList"
            :key="file.id"
          >
            <div v-if="file.mimeType == 'application/pdf'" class="pdf-viewer">
              <embed
                :src="`data:${file.mimeType};base64,${file.file}`"
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
            <div v-else style="text-align: center">
              <ImagePreview
                :src="`data:${file.mimeType};base64,${file.file}`"
                :alt="file.file_origin_name"
                preview
              />
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'gravar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveClassification"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import prospectService from "../../services/prospect.service";
import businessService from "../../services/business.service";
import unidecode from "unidecode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "BusinessClassificationForm",
  components: {
    Loading,
  },
  props: [
    "show",
    "showToast",
    "sellerId",
    "businessId",
    "businessName",
    "selected",
    "classification",
  ],
  watch: {
    async show() {
      if (this.show === true) {
        this.showLoader = false;
        this.current = this.sellerId;
        if (!this.selected || this.selected == undefined) {
          if (this.classification.id != undefined) {
            this.filesList =
              await businessService.getBusinessClassificationFiles(
                this.classification.id
              );
          }
        }
      } else {
        this.reset();
      }
    },
  },
  data() {
    return {
      current: !this.selected ? this.sellerId : null,
      sellerList: [],
      showLoader: false,
      solutionsList: [],
      businessAreasList: [],
      filesList: [],
      statusList: [
        { id: 0, label: "Por Classificar" },
        { id: 1, label: "Não Divulgar" },
        { id: 2, label: "Portfólio" },
        { id: 3, label: "Ocultar" },
      ],
    };
  },
  async created() {
    this.sellerList = await prospectService.getSellers();
    this.solutionsList = await businessService.getBusinessSolutionsList();
    this.businessAreasList = await businessService.getBusinessAreasList();
  },
  methods: {
    reset() {
      this.current = null;
      this.currentStatus = null;
      this.filesList = [];
      this.classification = {};
      this.showLoader = false;
    },
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    saveClassification() {
      if (this.selected != null) {
        return this.saveMultipleClassification();
      }
      var formData = new FormData();
      formData.append("seller_id", parseInt(this.current));
      if (this.classification.currentStatus == 1) {
        formData.append("hide", 0);
        formData.append("seller_edit", 0);
      } else if (this.classification.currentStatus == 2) {
        formData.append("hide", 0);
        formData.append("seller_edit", 1);
      } else if (this.classification.currentStatus == 3) {
        formData.append("hide", 1);
        formData.append("seller_edit", 0);
      } else if (this.classification.currentStatus == 0) {
        formData.append("delete", 1);
      }
      // formData.append(
      //   "portfolio",
      //   this.classification.portfolio == true
      //     ? 1
      //     : this.classification.portfolio == false
      //     ? 0
      //     : null
      // );
      formData.append(
        "case_study",
        this.classification.case_study == true
          ? 1
          : this.classification.case_study == false
          ? 0
          : null
      );
      // formData.append(
      //   "social_networks",
      //   this.classification.social_networks == true
      //     ? 1
      //     : this.classification.social_networks == false
      //     ? 0
      //     : null
      // );
      // formData.append(
      //   "seller_edit",
      //   this.classification.seller_edit == true
      //     ? 1
      //     : this.classification.seller_edit == false
      //     ? 0
      //     : null
      // );
      formData.append(
        "marketing_action",
        this.classification.marketing_action
          ? this.classification.marketing_action
          : ""
      );
      formData.append(
        "actions_taken",
        this.classification.actionsTaken ? this.classification.actionsTaken : ""
      );
      formData.append(
        "description_project",
        this.classification.description_project
          ? this.classification.description_project
          : ""
      );
      // formData.append(
      //   "hide",
      //   this.classification.hide == true
      //     ? 1
      //     : this.classification.hide == false
      //     ? 0
      //     : null
      // );
      formData.append(
        "marketing_name",
        this.classification.marketing_name
          ? this.classification.marketing_name
          : ""
      );
      // formData.append(
      //   "sites",
      //   this.classification.sites == true
      //     ? 1
      //     : this.classification.sites == false
      //     ? 0
      //     : null
      // );
      formData.append("id", this.businessId);

      if (this.classification.solutions != undefined) {
        formData.append("solutions", this.classification.solutions.join(";"));
      }
      if (this.classification.business_area != undefined) {
        formData.append(
          "business_areas",
          this.classification.business_area.join(";")
        );
      }
      let files = this.$refs["filesInput"];
      if (files.files.length > 0) {
        files.files.forEach((file) => {
          formData.append("files[]", file);
        });
      }
      this.showLoader = true;

      return businessService
        .saveBusinessClassification(formData)
        .then((response) => {
          this.showLoader = false;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao alterar o atualizar o negócio",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("savedBusinessClassification", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Negócio alterado com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("savedBusinessClassification", {
            status: "ok",
            result: response,
          });
        });
    },
    async saveMultipleClassification() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      var formData = new FormData();
      formData.append("seller_id", parseInt(this.current));
      if (this.classification.currentStatus == 1) {
        formData.append("hide", 0);
        formData.append("seller_edit", 0);
      } else if (this.classification.currentStatus == 2) {
        formData.append("hide", 0);
        formData.append("seller_edit", 1);
      } else if (this.classification.currentStatus == 3) {
        formData.append("hide", 1);
        formData.append("seller_edit", 0);
      } else if (this.classification.currentStatus == 0) {
        formData.append("delete", 1);
      }
      // formData.append(
      //   "portfolio",
      //   this.classification.portfolio == true
      //     ? 1
      //     : this.classification.portfolio == false
      //     ? 0
      //     : null
      // );
      // formData.append(
      //   "case_study",
      //   this.classification.case_study == true
      //     ? 1
      //     : this.classification.case_study == false
      //     ? 0
      //     : null
      // );
      // formData.append(
      //   "social_networks",
      //   this.classification.social_networks == true
      //     ? 1
      //     : this.classification.social_networks == false
      //     ? 0
      //     : null
      // );
      // formData.append(
      //   "seller_edit",
      //   this.classification.seller_edit == true
      //     ? 1
      //     : this.classification.seller_edit == false
      //     ? 0
      //     : null
      // );
      formData.append(
        "marketing_action",
        this.classification.marketing_action
          ? this.classification.marketing_action
          : ""
      );
      formData.append(
        "actions_taken",
        this.classification.actionsTaken ? this.classification.actionsTaken : ""
      );
      formData.append(
        "description_project",
        this.classification.description_project
          ? this.classification.description_project
          : ""
      );
      // formData.append(
      //   "hide",
      //   this.classification.hide == true
      //     ? 1
      //     : this.classification.hide == false
      //     ? 0
      //     : null
      // );
      formData.append(
        "marketing_name",
        this.classification.marketing_name
          ? this.classification.marketing_name
          : ""
      );
      // formData.append(
      //   "sites",
      //   this.classification.sites == true
      //     ? 1
      //     : this.classification.sites == false
      //     ? 0
      //     : null
      // );
      formData.append("id", this.businessId);

      if (this.classification.solutions != undefined) {
        formData.append("solutions", this.classification.solutions.join(";"));
      }
      if (this.classification.business_area != undefined) {
        formData.append(
          "business_areas",
          this.classification.business_area.join(";")
        );
      }
      let files = this.$refs["filesInput"];
      if (files.files.length > 0) {
        files.files.forEach((file) => {
          formData.append("files[]", file);
        });
      }

      for (const business of this.selected) {
        formData.set("id", business.reference);
        await businessService
          .saveBusinessClassification(formData)
          .then((response) => {
            if (!response) {
              return (noErrors = false);
            }
            res = response;
          });
      }
      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar o atualizar o negócio",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("savedBusinessClassification", {
          status: "error",
        });
      }

      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Negócio alterado com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("savedBusinessClassification", {
        status: "ok",
        result: res,
      });
    },
    getSearchLabel(info) {
      return `${unidecode(info.name)}`;
    },
    caseStudyChange() {
      if (this.classification.case_study == true) {
        this.classification.portfolio = true;
      }
    },
  },
};
</script>
<style>
.p-multiselect-label {
  display: contents !important;
}
.p-multiselect {
  min-height: 33px !important;
}
.p-multiselect-token {
  margin: 2px;
  background-color: #fff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #3498db !important;
  margin-bottom: 2px;
}
</style>
